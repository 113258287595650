import { useEffect } from "react";

import { Divider, PageHeading, PageSection, SectionContainer, TrainingGroupCards } from '../Components/Components';
import { Button } from "@blueprintjs/core";

import AuskickLogo from "../img/NAB_AFL_Auskick_Logo.webp";

function AboutAuskick() {
    return (
        <PageSection
            id="about-auskick"
            title="About Auskick"
        >
            <img
                src={AuskickLogo}
                alt="Auskick Logo"
                height={100}
            />
            <h3>What is Auskick?</h3>
            <p>
                Auskick is a program run by the AFL to introduce kids to the game of Australian
                Rules Football. It is a fun, safe and inclusive program that teaches kids the
                basic skills of the game. The program is open to kids aged 5 to 12 years old,
                and is run by Port Stephens AFL Club Coaches. Kids are able to start the Auskick
                program the year that they turn 5. Kids turning 7 to 12 have the option to join
                a junior or youth team if they wish to play competition instead of Auskick. A
                junior player must be a minimum of seven (7) years of age by July 31st in the
                year of the relevant season to be eligible to be registered for the junior
                competition.
            </p>
            <h3>What does the program include?</h3>
            <p>
                Each week the Auskick will have their own training session that runs for 1 hour
                at Ferodale Oval, Medowie. This is usually conducted on the same day as our
                junior teams training but may differ each season depending on field use, coach
                availability and the needs of the Auskick group as a collective. In recent
                seasons, training has been held either on a Tuesday or Wednesday at 5pm.
            </p>
            <p>
                While the Auskick program does not include regular competition of games, Port
                Stephens AFL Club conducts in house fun game play on Junior home rounds on a
                Sunday at Ferodale Oval during the football season. This may be approximately
                4-5 times a season. At the end of the season Port Stephens AFL Club provides our
                Auskick members with an engraved medal for their participation.
            </p>
            <p>
                In recent seasons we have hosted the Nelson Bay Marlins Auskick at Ferodale Oval
                for local derby fun game play style match. Both teams created team banners that
                the kids were able to run through, just like a real AFL match!
            </p>
            <h3>What kit is provided with registration?</h3>
            <p>
                The AFL itself provides a different Auskick player kit with registration each
                season. It may include a backpack, AFL training shirt and sticker, a ball and/or
                other AFL items. Each season a number of the Auskick team members are able to
                attend half time game play of the AFL Sydney Swans game held at the Sydney
                Cricket Ground which includes free entry for the registered member. These spots
                are limited and are usually decided on which families can to attend when we
                receive allocated tickets. Each season depending upon Port Stephens AFL Club
                sponsorship, we hope to offer our kids vouchers to local businesses for their
                efforts at training.
            </p>
            <h3>When is Auskick footy season?</h3>
            <p>
                Auskick usually runs from April through August each year. Kids register each
                season prior to undertaking the competition. A child can only be registered by a
                parent or guardian.
            </p>
            <p>
                Want more info? Visit <a
                    href="https://play.afl/play/auskick"
                    target="_blank"
                    rel="noreferrer"
                >
                    NAB AFL Auskick - Play AFL
                </a> and <a
                    href="https://play.afl/play/auskick/faqs"
                    target="_blank"
                    rel="noreferrer"
                >
                    FAQs - Play AFL
                </a>
            </p>
            <a
                id="register"
                href="https://play.afl/play"
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    className='port-stephens-afc-button'
                    text="Register Now"
                    rightIcon='chevron-right'
                />
            </a>
        </PageSection>
    );
}

function Sessions() {
    return (
        <PageSection
            id="sessions"
            title="Sessions"
        >
            <TrainingGroupCards trainingGroups={[
                {
                    group: "Auskick",
                    time: null,
                    fixtureLink: null
                }
            ]}/>
        </PageSection>
    );
}

export default function Auskick() {
    useEffect(() => {
        document.title = "Auskick | Port Stephens Power AFL";
    });

    return (
        <SectionContainer>
            <PageHeading
                text="Auskick – the program introducing kids to the game of AFL"
                links={[
                    { text: "About Auskick", sectionId: "about-auskick" },
                    { text: "Register", sectionId: "register" },
                    { text: "Sessions", sectionId: "sessions" },
                ]}
            />
            <Divider />
            <AboutAuskick />
            <Divider />
            <Sessions />
        </SectionContainer>
    );
};
