import { Boundary, Button, Menu, MenuItem, OverflowList, Popover } from '@blueprintjs/core';
import { useLayoutEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { ReactComponent as AflIcon } from '../img/icons/afl.svg';
import { ReactComponent as FacebookIcon } from '../img/icons/facebook-circle.svg';
import { ReactComponent as InstagramIcon } from '../img/icons/instagram-circle.svg';
import logo from '../img/logo.png';

import './Layout.scss';
import '@blueprintjs/core/lib/css/blueprint.css';

function NavbarLink({ to, href, text }: { to?: string; href?: string; text: string; }) {
    return (
        to
            ? <Link to={to}>
            <Button
                className="navbar-link"
                minimal={true}
                text={text}
            />
        </Link>
            : <a href={href}>
                <Button
                    className="navbar-link"
                    minimal={true}
                    text={text}
                />
            </a>
    );
}

function MoreButtonNavbarLink({ to, href, text }: { to?: string; href?: string; text: string; }) {
    return (
        to
            ? <Link to={to}>
            <MenuItem
                className="navbar-link"
                text={text}
            />
        </Link>
            : <a href={href}>
                <MenuItem
                    className="navbar-link"
                    text={text}
                />
            </a>
    );
}

function MoreButton({ overflowItems }: { overflowItems: { to?: string; href?: string; text: string; }[] }) {
    return (
        <Popover
            content={
                <Menu>
                    {overflowItems.map((item: { to?: string; href?: string; text: string; }) => <MoreButtonNavbarLink key={item.text} {...item} />)}
                </Menu>
            }
            interactionKind="hover"
            placement="bottom-end"
            popoverClassName="more-popover"
        >
            <Button
                minimal={true}
                className="navbar-link more-button"
                text="More"
                rightIcon="caret-down"
            />
        </Popover>
    );
}

function Navbar() {
    const pages = [
        { to: "/", text: "Home" },
        { to: "about", text: "About" },
        { to: "auskick", text: "Auskick" },
        { to: "juniors", text: "Juniors" },
        { to: "seniors", text: "Seniors" },
        { to: "coaches", text: "Coaches" },
        { to: "officials", text: "Officials" },
        { href: "https://www.facebook.com/PSPAFL/", text: "News" },
        { to: "contact", text: "Contact" }
    ];

    return (
        <div className="navbar">
            <div className="navbar-container">
                <div className="navbar-group">
                    <img
                        className="navbar-logo"
                        src={logo}
                        alt="Logo"
                    />
                    <div className="navbar-heading">
                        Port Stephens Power AFC
                    </div>
                    <div className="navbar-divider" />
                </div>
                <div className="navbar-group navbar-buttons">
                    <OverflowList
                        collapseFrom={Boundary.END}
                        items={pages}
                        overflowRenderer={overflowItems => <MoreButton overflowItems={overflowItems} />}
                        visibleItemRenderer={(item: { to?: string; href?: string; text: string; }) => <NavbarLink key={item.text} {...item} />}
                    />
                    <div className="navbar-divider" />
                    <a href="https://teamwear.s-trend.com.au/en/s/port-stephen">
                        <Button
                            minimal={true}
                            className="navbar-link shop-button"
                            text="Shop"
                            icon="shop"
                        />
                    </a>
                    <a
                        href="https://play.afl/play"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            className="register-button"
                            text="Register Now"
                            rightIcon="chevron-right"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

function Footer() {
    return (
        <div className="footer">
            <div className="footer-buttons">
                <a
                    href="https://www.facebook.com/PSPAFL"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        minimal={true}
                        icon={<FacebookIcon />}
                    />
                </a>
                <a
                    href="https://www.instagram.com/portstephensafc/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        minimal={true}
                        icon={<InstagramIcon />}
                    />
                </a>
                <a
                    href="https://www.playhq.com/search?query=port%20stephens&fbclid=IwAR2xDGqLhMCzVzMGWCpZkjQ8mxPDkQ2ZrhDNW2fiqrK05TgtbgtDHnjJxrQ"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        minimal={true}
                        icon={<AflIcon />}
                    />
                </a>
            </div>
            <span className="copyright">© 2024 Port Stephens Australian Football Club INC, Joshua Groombridge</span>
        </div>
    );
}

function useNavbarHeight() {
    const [navbarHeight, setNavbarHeight] = useState(80);
    useLayoutEffect(() => {
      function updateNavbarHeight() {
        const navbarElement = (document.getElementsByClassName('navbar')[0] as HTMLElement);
        setNavbarHeight(navbarElement.offsetHeight);
      }
      window.addEventListener('resize', updateNavbarHeight);
      updateNavbarHeight();
      return () => window.removeEventListener('resize', updateNavbarHeight);
    }, []);
    return navbarHeight;
  }

export default function Layout() {
    const navbarHeight = useNavbarHeight();
    return (
        <>
            <Navbar />
            <div
                className="page-content"
                style={{ paddingTop: navbarHeight }}
            >
                <Outlet />
                <Footer />
            </div>
        </>
    )
};
